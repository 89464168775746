import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, BasicCard, Grid } from "components/common"

import { gradientText, matrixBackgroundStyle, device } from "utils"

const AppTestimonials = ({ title, valueProps, backgroundImage }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => (
        <BasicCard>
          <ValueProp
            className={vp.vpId}
            {...vp}
            titleAs="h5"
            subtitleAs="pSmall"
          />
        </BasicCard>
      )}
    />
  )

  return (
    <StyledAppTestimonials>
      <VSection
        title={title}
        grid={renderGrid}
        backgroundImage={backgroundImage}
      />
    </StyledAppTestimonials>
  )
}

const StyledAppTestimonials = styled.div`
  --section-mobile-padding-bottom: var(--sp-96);
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    /* --tg-max-width: 711px; */
  }
  .v_mediaContainer {
    --section-align: center;
  }
  .v_gridContainer {
    --tg-text-align: center;
    --section-align: center;

    ul {
      align-items: flex-start;

      .basicCard {
        display: none;

        &:first-child {
          display: block;
        }

        @media ${device.laptop} {
          display: block;
        }
      }
    }

    .vp_mediaContainer {
      text-align: center;
      img {
        margin: 0 auto;
      }
    }

    .title {
      ${gradientText("var(--app-gradient-color)")};
    }
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle}
  }
`

export default AppTestimonials
