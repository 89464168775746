import React from "react"
import styled from "styled-components"

import { device } from "utils"
import { PricingCard } from "components/common"

const AppPricing = (props) => (
  <StyledAppPricing id="pricing">
    <PricingCard {...props} />
  </StyledAppPricing>
)

const StyledAppPricing = styled.div`
  padding: 0 var(--sp-section-lr);
  background: var(--section-background-color);
  @media ${device.laptop} {
    padding: var(--sp-160) var(--sp-section-lr) var(--section-padding);
  }

  .tgContainer {
    --button-background: var(--app-theme-color);
    --button-shadow: var(--button-shadow_2);

    .title {
      .bold {
        color: var(--app-theme-color);
      }
    }
  }
`

export default AppPricing
