import React from "react"
import styled from "styled-components"

import { Cta, VSection } from "components/common"

const AppBeta = ({ title, subtitle, ctas }) => {
  return (
    <StyledAppBeta>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        cta={() => <Cta ctas={ctas} />}
      />
    </StyledAppBeta>
  )
}

const StyledAppBeta = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);

  --section-align: center;
  --tg-text-align: center;
  --tg-max-width: 600px;

  --tg-subtitle-color: var(--highlight_3);

  .v_ctaContainer {
    margin-top: var(--sp-24);

    button {
      --button-background: var(--app-theme-color);
      --button-shadow: var(--button-shadow_2);
    }
  }
`

export default AppBeta
