import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import map from "lodash/map"

import { device } from "utils"

const CompareTableManager = ({ children, selected }) => {
  const col = children.length || 1

  return (
    <StyledCompareTableManager col={col} selected={selected}>
      {children}
    </StyledCompareTableManager>
  )
}

const StyledCompareTableManager = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  .cell {
    display: none;

    ${({ selected }) => `
      &:nth-child(${selected + 1}) {
      display: grid;
    }
    `};

    @media ${device.laptop} {
      display: grid;
    }

    &:first-child {
      border-right: var(--table-border);
    }
    &:last-child {
      border-right: none;
    }
  }

  @media ${device.laptop} {
    grid-template-columns: ${({ col }) => `repeat(${col}, 1fr)`};
  }
`

const CompareTableSwitcher = ({ icons, title, selected, setSelected }) => {
  return (
    <StyledCompareTableSwitcher>
      <p className="small">{title}</p>
      <ul>
        {map(icons, ({ active, inactive }, idx) => (
          <li key={idx} className={classNames({ selected: selected === idx })}>
            <button onClick={() => setSelected(idx)}>
              <img src={active} alt="active" className="active" />
              <img src={inactive} alt="inactive" className="inactive" />
            </button>
          </li>
        ))}
      </ul>
    </StyledCompareTableSwitcher>
  )
}

const StyledCompareTableSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 100px; */
  margin-bottom: 120px;

  @media ${device.laptop} {
    display: none;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 65px));
    grid-gap: var(--sp-24);
    justify-content: center;
    margin-top: var(--sp-16);
    width: 100%;

    li {
      position: relative;
      height: 65px;
      width: 65px;

      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.2s ease-in-out;
        border-radius: 50%;
        transition-property: opacity, visibility, box-shadow;
        will-change: opacity, visibility, box-shadow;

        &.active {
          opacity: 0;
          visibility: hidden;
          box-shadow: var(--card-shadow);
        }
      }

      &.selected {
        .active {
          opacity: 1;
          visibility: visible;
        }
        .inactive {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
`

export { CompareTableManager, CompareTableSwitcher }
