import React from "react"
import styled from "styled-components"

import { TextGroup } from "components/common"

import { device, richTextBody } from "utils"

const AppFullFeature = ({ title, leftFeatures, rightFeatures }) => {
  return (
    <StyledAppFullFeature full={!rightFeatures}>
      <TextGroup title={title} />

      <div className="features">
        <TextGroup className="left" subtitle={leftFeatures} />
        {rightFeatures && (
          <TextGroup className="right" subtitle={rightFeatures} />
        )}
      </div>
    </StyledAppFullFeature>
  )
}

const StyledAppFullFeature = styled.div`
  --tg-text-align: center;
  --section-align: center;

  .features {
    background: var(--highlight_2);
    box-shadow: var(--card-shadow_light);
    border: var(--card-border_light);
    border-radius: var(--card-radius);
    display: grid;
    grid-template-columns: 1fr;
    margin-top: var(--sp-16);

    @media ${device.laptop} {
      margin: 0 auto;
      margin-top: var(--sp-48);
      grid-template-columns: ${({ full }) => (full ? "1fr" : "1fr 1fr")};
      width: 100%;
      max-width: 450px;
    }

    .tgWrap {
      --tg-text-align: left;
      --tg-grid-gap: 0;
      ${richTextBody};

      ul {
        margin-bottom: 0;
        padding-left: 16px; // the bullet spacing??
        li,
        .subtitle {
          color: var(--card-shade_3);
          font-size: 0.875rem;
          line-height: 25px;
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 0;
      }
    }

    .left,
    .right {
      padding: var(--sp-24);
      @media ${device.laptop} {
        padding: var(--sp-48);
      }
    }

    .left {
      @media ${device.laptop} {
        border-right: ${({ full }) =>
          full ? "none" : "1px solid rgba(94, 98, 111, 0.24)"};
      }
    }
  }
`

export default AppFullFeature
