import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import {
  VSection,
  TextGroup,
  Media,
  Modal,
  Link,
  Button,
  Video,
} from "components/common"

import { device } from "utils"
import WistiaPlayer from "./WistiaPlayer"

const AppHero = ({
  title,
  subtitle,
  media,
  backgroundImage,
  ctas,
  components,
  slug,
  salesforceAppexchangeUrl,
  disabled,
}) => {
  const rating = components && components[0]

  const renderCtas = () => {
    return (
      <div className="cta_grid">
        {map(ctas, ({ id, url, text }, idx) => {
          if (idx === 0) {
            if (disabled) {
              return null
            }
            return (
              <Link key={id} href={salesforceAppexchangeUrl} target="_blank">
                <Button className="try-it-now">{text}</Button>
              </Link>
            )
          }

          if (url === "uyipui27de" || url === "wp0beh2ghs") {
            return (
              <Modal
                key={id}
                trigger={
                  <Button key={id} className="watch-demo">
                    {text}
                  </Button>
                }
              >
                <WistiaPlayer videoId={url} />
              </Modal>
            )
          }

          return (
            <Modal
              key={id}
              trigger={
                <Button key={id} className="watch-demo">
                  {text}
                </Button>
              }
            >
              <Video youtube id="app-watch-demo" src={url} />
            </Modal>
          )
        })}
      </div>
    )
  }

  return (
    <StyledAppHero id="overview" className={slug} hideCta={disabled}>
      <VSection
        title={title}
        subtitle={subtitle}
        subtitleAs="pSmall"
        titleAs="h1"
        backgroundImage={backgroundImage}
        media={media}
        critical
        // cta={() => <Cta ctas={ctas} />}
        cta={renderCtas}
        custom={() =>
          rating && (
            <div className="ratingContainer">
              <Media media={{ desktop: rating.icon }} />
              <TextGroup subtitle={rating.subtitle} subtitleAs="pSmall" />
            </div>
          )
        }
      />
    </StyledAppHero>
  )
}

const StyledAppHero = styled.div`
  /* position: relative; */
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);

  .v_tgContainer {
    --tg-text-align: center;
    --tg-max-width: 730px;
    --section-align: center;

    .subtitle {
      .bold {
        color: var(--app-theme-color);
      }
    }
  }

  .v_mediaContainer {
    --section-mobile-padding-left: 0;
    --section-mobile-padding-right: 0;
  }

  .v_ctaContainer {
    --section-order: 2;
    --section-mobile-order: 2;
    --section-align: center;
    margin-top: var(--sp-32);

    .cta_grid {
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      grid-template-columns: auto auto;

      grid-gap: ${({ hideCta }) => !hideCta && "12px"};
      --button-padding: 14px;
      --button-mobile-font-size: 12px;

      @media ${device.laptop} {
        grid-gap: ${({ hideCta }) => !hideCta && "var(--sp-24)"};
        --button-font-size: 1fr;
        --button-padding: 20px 24px;
      }
      .try-it-now {
        --button-background: var(--app-theme-color);
        --button-shadow: var(--button-shadow_2);
      }
    }
  }
  .v_customContainer {
    --section-order: 3;
    --section-mobile-order: 3;
    --section-align: center;
    margin-top: var(--sp-32);

    .ratingContainer {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      grid-gap: var(--sp-8);

      @media ${device.laptop} {
        grid-template-columns: auto auto;
      }

      img {
        margin: 0 auto;
      }

      .subtitle {
        max-width: 269px;
        text-align: center;

        @media ${device.laptop} {
          text-align: left;
        }
      }
    }

    /* .bold {
      font-weight: 700;
      font-size: 2rem;
      color: var(--app-theme-color);
    } */
  }

  .v_backgroundImage {
    display: none;

    @media ${device.laptop} {
      left: 0;
      right: 0;
      bottom: 15vh;
      width: 100%;
      text-align: center;
      display: block;
      bottom: 10vh;
    }
    @media ${device.desktop} {
      bottom: 15vh;
    }
    img {
      width: 100%;
      height: 100%;
      max-width: 1440px;
    }
  }
`

export default AppHero
