import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import classNames from "classnames"

import { AppCompareTableStyles, Cell, device } from "src/utils"

// import {} from "src/components/tables"

import TableCheckmark from "src/images/common/table_checkmark.inline.svg"
import TableNo from "src/images/common/table_no.inline.svg"

const tableRows = [
  "ups",
  "fedex",
  "dhlExpress",
  "usps",
  "uspsEndicia",
  "uspsStamps",
]

const ShipFullFeatureTable = ({ slug, table, switcherTitle }) => {
  const renderCell = ({ cell, feature }) => {
    if (feature === "hide_company") {
      return
    }

    if (cell === "TRUE") {
      return <TableCheckmark />
    } else if (cell === "FALSE") {
      return <TableNo />
    }
    return cell
  }

  const renderFeature = (val) => {
    const field = val.includes("hide_")
    if (field) {
      return ""
    }

    return val
  }

  return (
    <StyledShipFullFeatureTable>
      <AppCompareTableStyles slug={slug}>
        {map(table.edges, ({ node }, idxA) => (
          <li key={idxA} className={classNames("row", node.feature)}>
            <Cell className="cell feature" slug={slug}>
              {renderFeature(node.feature)}
            </Cell>

            {map(tableRows, (row, idx) => (
              <Cell key={idx} className={classNames("cell", row)} slug={slug}>
                {renderCell({ cell: node[row] })}
              </Cell>
            ))}
          </li>
        ))}
      </AppCompareTableStyles>
    </StyledShipFullFeatureTable>
  )
}

const StyledShipFullFeatureTable = styled.div`
  /* max-width: 1080px;
  margin: 0 auto; */
  .row {
    grid-template-columns: 25% repeat(6, 1fr);
    @media ${device.laptop} {
      grid-template-columns: 25% repeat(6, 1fr);
    }

    &:first-child {
      border-bottom: var(--table-border);
    }

    &.hide_company {
      .cell {
        font-size: 0.625rem;
        line-height: 1;
        padding-left: 0;
        padding-right: 0;
        @media ${device.laptop} {
          font-size: 0.875rem;
        }
      }
    }

    .cell {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      &.feature {
        padding-left: var(--sp-8);
        border-right: var(--table-border);

        @media ${device.laptop} {
          padding-left: var(--sp-24);
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
`

export default ShipFullFeatureTable
