import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { VSection } from "components/common"

import { ShipFullFeatureTable } from "components/tables"

import { matrixBackgroundStyle } from "utils"

const ShipFullFeatures = ({ title, subtitle }) => {
  const { ship } = useStaticQuery(QUERY)

  return (
    <StyledShipFullFeatures>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        custom={() => (
          <ShipFullFeatureTable
            table={ship}
            slug="ship"
            switcherTitle="Compare Ship by Opero:"
          />
        )}
        // backgroundImage={backgroundImage}
      />
    </StyledShipFullFeatures>
  )
}

const StyledShipFullFeatures = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  position: relative;

  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
  }

  .v_customContainer {
    --v-section-mobile-custom-top: var(--sp-32);
    --v-section-custom-top: var(--sp-64);
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

const QUERY = graphql`
  {
    ship: allGoogleSpreadsheetShipFeatures {
      edges {
        node {
          feature
          ups
          fedex
          dhlExpress
          usps
          uspsEndicia
          uspsStamps
        }
      }
    }
  }
`

export default ShipFullFeatures
