import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, Grid } from "components/common"

import { matrixBackgroundStyle } from "utils"

const AppBadges = ({ title, valueProps, backgroundImage }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => <ValueProp className={vp.vpId} {...vp} />}
    />
  )

  return (
    <StyledAppBadges>
      <VSection
        title={title}
        grid={renderGrid}
        backgroundImage={backgroundImage}
      />
    </StyledAppBadges>
  )
}

const StyledAppBadges = styled.div`
  --section-mobile-padding-bottom: var(--sp-32);
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    --tg-max-width: 711px;
  }
  .v_mediaContainer {
    --section-align: center;
  }
  .v_gridContainer {
    --tg-text-align: center;
    --section-align: center;
    --grid-col-max: 240px;
    --vp-align: center;
    --grid-gap: var(--sp-48);
    --mobile-grid-gap: var(--sp-48);

    .vp_mediaContainer {
      text-align: center;
      margin: 0 auto;

      img {
        height: 118px;
        width: 118px;
      }
    }

    .vp_textContainer {
      align-self: flex-start;
    }
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

export default AppBadges
