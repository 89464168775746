import React, { useState } from "react"
import styled from "styled-components"
import map from "lodash/map"
import classNames from "classnames"

import { AppCompareTableStyles, Cell, appTextFromSlug } from "src/utils"

import {
  CompareTableSwitcher,
  CompareTableManager,
} from "src/components/tables"

import TableCheckmark from "src/images/common/table_checkmark.inline.svg"
import TableNo from "src/images/common/table_no.inline.svg"

// Icons that need to be switched out...
import AppLogo from "src/images/compare/companies/payments/payment_compare_icon_desktop.inline.svg"

import chargent_active from "src/images/compare/companies/payments/pay_compare_comp1_active.png"
import chargent_inactive from "src/images/compare/companies/payments/pay_compare_comp1_inactive.png"

import linvio_active from "src/images/compare/companies/payments/pay_compare_comp2_active.png"
import linvio_inactive from "src/images/compare/companies/payments/pay_compare_comp2_inactive.png"

import blackthorn_active from "src/images/compare/companies/payments/pay_compare_comp3_active.png"
import blackthorn_inactive from "src/images/compare/companies/payments/pay_compare_comp3_inactive.png"

import kulturra_active from "src/images/compare/companies/payments/pay_compare_comp3_active-1.png"
import kulturra_inactive from "src/images/compare/companies/payments/pay_compare_comp3_inactive-1.png"

const switcherIcons = [
  {
    active: chargent_active,
    inactive: chargent_inactive,
  },
  {
    active: linvio_active,
    inactive: linvio_inactive,
  },
  {
    active: blackthorn_active,
    inactive: blackthorn_inactive,
  },
  {
    active: kulturra_active,
    inactive: kulturra_inactive,
  },
]

const PaymentsCompareTable = ({ slug, table, switcherTitle }) => {
  const renderCell = ({ cell, feature, opero }) => {
    if (opero && feature === "hide_company") {
      return <h2 className="app-text">{appTextFromSlug[slug]}.</h2>
    }

    if (cell === "TRUE") {
      return <TableCheckmark />
    } else if (cell === "FALSE") {
      return <TableNo />
    }
    return cell
  }

  const renderFeature = (val) => {
    const field = val.includes("hide_")
    if (field) {
      return ""
    }

    return val
  }

  const [selected, setSelected] = useState(0)

  return (
    <StyledPaymentsCompareTable>
      {/* selector */}

      <CompareTableSwitcher
        selected={selected}
        setSelected={setSelected}
        title={switcherTitle}
        icons={switcherIcons}
      />

      <AppCompareTableStyles slug={slug}>
        {map(
          table.edges,
          (
            {
              node: {
                id,
                feature,
                opero,
                chargent,
                linvio,
                blackthorn,
                kulturra,
              },
            },
            idx
          ) => (
            <li key={id} className={classNames("row", feature)}>
              <Cell className="cell feature" slug={slug}>
                {renderFeature(feature)}
              </Cell>
              <Cell className="cell opero" slug={slug}>
                {idx === 0 && (
                  <div className="col_hat">
                    <div className="logoWrap">
                      <AppLogo className="app_logo" />
                    </div>
                  </div>
                )}
                {renderCell({ cell: opero, feature, opero: true })}
              </Cell>
              <CompareTableManager selected={selected}>
                <Cell className="cell chargent" slug={slug}>
                  {renderCell({ cell: chargent })}
                </Cell>
                <Cell className="cell linvio" slug={slug}>
                  {renderCell({ cell: linvio })}
                </Cell>
                <Cell className="cell blackthorn" slug={slug}>
                  {renderCell({ cell: blackthorn })}
                </Cell>
                <Cell className="cell kulturra" slug={slug}>
                  {renderCell({ cell: kulturra })}
                </Cell>
              </CompareTableManager>
            </li>
          )
        )}
      </AppCompareTableStyles>
    </StyledPaymentsCompareTable>
  )
}

const StyledPaymentsCompareTable = styled.div``

export default PaymentsCompareTable
