import React, { useState } from "react"
import styled from "styled-components"
import map from "lodash/map"
import classNames from "classnames"

import { AppCompareTableStyles, Cell } from "src/utils"

import {
  CompareTableSwitcher,
  CompareTableManager,
} from "src/components/tables"

import TableCheckmark from "src/images/common/table_checkmark.inline.svg"
import TableNo from "src/images/common/table_no.inline.svg"

// Icons that need to be switched out...
import AppLogo from "src/images/apps/ship/ship_icon_desktop.inline.svg"

import ship_active from "src/images/compare/companies/ship/ship_compare_comp1_active.png"
import ship_inactive from "src/images/compare/companies/ship/ship_compare_comp1_inactive.png"

const switcherIcons = [
  {
    active: ship_active,
    inactive: ship_inactive,
  },
]

const ShipCompareTable = ({ slug, table, switcherTitle }) => {
  const renderCell = ({ cell, feature, opero }) => {
    if (opero && feature === "hide_company") {
      return <h2>{slug}.</h2>
    }

    if (cell === "TRUE") {
      return <TableCheckmark />
    } else if (cell === "FALSE") {
      return <TableNo />
    }
    return cell
  }

  const renderFeature = (val) => {
    const field = val.includes("hide_")
    if (field) {
      return ""
    }

    return val
  }

  const [selected, setSelected] = useState(0)

  return (
    <StyledShipCompareTable>
      {/* selector */}

      <CompareTableSwitcher
        selected={selected}
        setSelected={setSelected}
        title={switcherTitle}
        icons={switcherIcons}
      />

      <AppCompareTableStyles slug={slug}>
        {map(table.edges, ({ node: { id, feature, opero, zenKraft } }, idx) => (
          <li key={id} className={classNames("row", feature)}>
            <Cell className="cell feature" slug={slug}>
              {renderFeature(feature)}
            </Cell>
            <Cell className="cell opero" slug={slug}>
              {idx === 0 && (
                <div className="col_hat">
                  <div className="logoWrap">
                    <AppLogo className="app_logo" />
                  </div>
                </div>
              )}
              {renderCell({ cell: opero, feature, opero: true })}
            </Cell>
            <CompareTableManager selected={selected}>
              <Cell className="cell zenKraft" slug={slug}>
                {renderCell({ cell: zenKraft })}
              </Cell>
            </CompareTableManager>
          </li>
        ))}
      </AppCompareTableStyles>
    </StyledShipCompareTable>
  )
}

const StyledShipCompareTable = styled.div``

export default ShipCompareTable
