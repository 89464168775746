import React from "react"
import styled from "styled-components"

import { VSection, Cta } from "components/common"

import { device } from "utils"

const AppComparison = ({ title, media, ctas }) => {
  return (
    <StyledAppComparison>
      <VSection
        title={title}
        media={media}
        custom={() => <Cta ctas={ctas} />}
      />
    </StyledAppComparison>
  )
}

const StyledAppComparison = styled.div`
  position: relative;
  z-index: 1;

  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    position: absolute;
    z-index: 2;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    --section-align: center;
    --tg-max-width: 210px;

    .tgWrap {
      margin-top: var(--sp-48);

      @media ${device.laptop} {
        margin-top: 0;
      }
    }

    @media ${device.laptop} {
      --tg-max-width: 700px;
    }
  }

  .v_mediaContainer {
    --section-align: center;
    --v-section-media-top: 0;
    z-index: 1;
    position: relative;
  }

  .v_customContainer {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    bottom: 45px;
    @media ${device.laptop} {
      bottom: 110px;
    }

    a {
      width: 180px;
      &:hover {
        opacity: 1;
        button {
          --button-background: var(--card-shade_1);
          opacity: 1;
        }
      }

      @media ${device.laptop} {
        width: auto;
      }
    }
  }
`

export default AppComparison
