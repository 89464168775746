import React from "react"
import styled from "styled-components"

import { Grid, VSection } from "components/common"
import { AppFullFeature } from "src/sections/app"
import { matrixBackgroundStyle } from "utils"

const AppFullFeatures = ({
  title,
  subtitle,
  appFullFeatures,
  // backgroundImage,
}) => {
  const renderFullFeatures = () =>
    appFullFeatures && (
      <Grid
        list={appFullFeatures}
        component={(feature) => <AppFullFeature {...feature} />}
      />
    )

  return (
    <StyledAppFullFeatures>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="pSmall"
        grid={renderFullFeatures}
        // backgroundImage={backgroundImage}
      />
    </StyledAppFullFeatures>
  )
}

const StyledAppFullFeatures = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  position: relative;

  .v_tgContainer {
    --tg-text-align: center;
    /* --tg-max-width: 670px; */
    --section-align: center;

    a {
      color: var(--link_light);
    }
  }

  .v_gridContainer {
    --grid-col: 1;
    --grid-gap: var(--sp-120);
    --mobile-grid-gap: var(--sp-120);

    --v-section-mobile-grid-top: var(--sp-64);
    --v-section-grid-top: var(--sp-96);
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

export default AppFullFeatures
