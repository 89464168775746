import React from "react"
import styled from "styled-components"

import { VSection, FeatureCard, ValueProp, Grid, Cta } from "components/common"

const AppFeatures = ({ features }) => {
  const renderFeatures = () => (
    <Grid
      className="featuresGrid"
      list={features}
      component={({ id, valueProps, ctas }) => {
        if (valueProps.length >= 2) {
          return (
            <div>
              <Grid
                className="smallFeaturesGrid"
                list={valueProps}
                component={(vp) => <ValueProp titleAs="h2" {...vp} />}
              />
              {ctas && (
                <div className="ctaWrap">
                  <Cta ctas={ctas} />
                </div>
              )}
            </div>
          )
        }
        return <FeatureCard {...valueProps[0]} />
      }}
    />
  )

  return (
    <StyledAppFeatures id="app_features">
      <VSection grid={renderFeatures} />
    </StyledAppFeatures>
  )
}

const StyledAppFeatures = styled.div`
  --section-padding-bottom: var(--sp-120);
  --section-mobile-padding-bottom: var(--sp-96);
  .featuresGrid {
    --grid-col: 1;
    --grid-gap: 128px;
    --mobile-grid-gap: 118px;

    .featureCard {
      .cta {
        --button-background: var(--app-theme-color);
        margin-top: var(--sp-24);
      }
    }

    .smallFeaturesGrid {
      margin: 0 auto;
      --grid-col: 2;
      --grid-col-max: 440px;
      --grid-gap: var(--sp-80);
      --mobile-grid-gap: 118px;
      --tg-text-align: center;
      --grid-align: center;
      --vp-grid-gap: var(--sp-32);
      --vp-mobile-grid-gap: var(--sp-32);

      li {
        /* grid-template-rows: max-content; */
      }

      .title {
        margin: 0 auto;
      }

      .vp_mediaContainer {
        text-align: center;
        max-height: 212px;

        img {
          max-height: 212px;
          object-fit: contain !important;
        }
      }
    }

    .ctaWrap {
      text-align: center;
      margin-top: var(--sp-64);
      --button-background: var(--app-theme-color);
    }
  }
`

export default AppFeatures
